<template>
  <div>
    <!-- Table -->
    <b-table
      id="propiedadesTable"
      v-if="getAvailableList"
      :fields="fields"
      :items="getResourceList"
      striped
      bordered
      responsive
      hover
      stacked="lg"
      show-empty
      small
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :filter-function="filterFunction"
      @filtered="onFiltered"
      empty-filtered-text="No se encontraron departamentos con el criterio de búsqueda escrito"
    >
      <!-- Eliminado -->
      <template #cell(is_deleted)="row">
        <b-badge v-if="row.item.is_deleted" variant="danger">No</b-badge>
        <b-badge v-else variant="light">Sí</b-badge>
      </template>

      <!-- # Puerta -->
      <template #cell(numero)="row">
        <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.numero }}
        </span>
      </template>

      <!-- # Puerta -->
      <!-- # Catastral -->
      <template #cell(num_catastral)="row">
        <filtered-row :value="row.item.num_catastral" :filter="filter"/>
      </template>

      <!-- # Predial -->
      <template #cell(cuenta_predial)="row">
        <filtered-row :value="row.item.cuenta_predial" :filter="filter"/>
      </template>

      <template #cell(empresa.razon_social)="row">
        <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.empresa.razon_social }}
        </span>
      </template>

      <template #cell(condominio.nombre)="row">
        <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.condominio.nombre }}
        </span>
      </template>

      <!-- Tipo -->
      <template #cell(tipo.nombre)="row">
        <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.tipo.nombre }}
        </span>
      </template>

      <!-- Inicio contrato -->
      <template #cell(inicio_contrato)="row">
        <filtered-row :value="row.item.inicio_contrato" :filter="filter"/>
      </template>

      <!-- Término contrato -->
      <template #cell(termino_contrato)="row">
        <filtered-row :value="row.item.termino_contrato" :filter="filter"/>
      </template>

      <!-- Renta inicial -->
      <template #cell(renta_inicial)="row">
        <span class="text-sm text-right w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ $formatNumber(row.item.renta_inicial) }}
        </span>
      </template>

      <!-- Renta actual -->
      <template #cell(renta_actual)="row">
        <span class="text-sm text-right w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ $formatNumber(row.item.renta_actual) }}
        </span>
      </template>

      <!-- Descuento -->
      <template #cell(descuento)="row">
        <span class="text-sm text-right w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ $formatNumber(row.item.descuento) }}
        </span>
      </template>

      <!-- Amueblado -->
      <template #cell(amueblado)="row">
        <span class="text-sm text-right w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.amueblado == 1 ? "Si":"No" }}
        </span>
      </template>

      <!-- Forma de pago -->
      <template #cell(forma_pago.nombre)="row">
        <span class="text-sm text-right w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ row.item.forma_pago.nombre }}
        </span>
      </template>

      <!-- Detalle -->
      <template #cell(historial)="row">
        <b-button
          @click="row.toggleDetails"
          variant="light"
          class="btn-sm mb-2 btn-block btn-outline-black"
        >
          Ver historial
        </b-button>
      </template>

      <!-- Acciones -->
      <template #cell(acciones)="row">
        <div class="d-flex justify-content-center" v-if="!row.item.is_deleted">
          <u-d-controls
            :id="row.item.id"
            editPath="propiedadModule/getResourceToEdit"
            deletePath="propiedadModule/deleteResource"
            :editPermissions="['actualizar propiedades']"
            :deletePermissions="['eliminar propiedades']"
            @on-got-item="emitOnGotItem"
          ></u-d-controls>
        </div>
      </template>

      <!-- Acciones -->
      <template #row-details="row">
        <b-card
          class="mb-1"
          border-variant="primary"
          header-bg-variant="primary"
          header-text-variant="white"
          header="Detalle"
          no-body
        >
          <b-card-body>
            <b-row class="mb-2">
              <b-col cols="12" md="6">
                <b-card
                  header="Historial de actualizaciones de importe por INPC"
                  no-body
                  header-bg-variant="primary"
                  header-text-variant="light"
                  border-variant="primary"
                  class="mb-4"
                  style="height: 300px; overflow: auto;"
                >
                  <b-table-lite
                    id="propiedadesTable"
                    :fields="fields_actualizacion"
                    :items="row.item.historial_actualizaciones"
                    striped
                    bordered
                    responsive
                    hover
                    stacked="lg"
                    show-empty
                    small
                  >
                  </b-table-lite>
                </b-card>
              </b-col>
              <b-col cols="12" md="6">
                <b-card
                  no-body
                  header="Historial de renovaciones de contrato"
                  header-bg-variant="warning"
                  header-text-variant="light"
                  border-variant="warning"
                  style="height: 300px; overflow: auto;"
                >
                  <b-table-lite
                    id="propiedadesTable"
                    :fields="fields_renovacion"
                    :items="row.item.historial_renovaciones"
                    striped
                    bordered
                    responsive
                    hover
                    stacked="lg"
                    show-empty
                    small
                  >
                  </b-table-lite>
                </b-card>
              </b-col>
            </b-row>

            <b-card no-body>
              <b-button
                variant="white"
                size="sm"
                class="btn-outline-black"
                @click="row.toggleDetails"
              >
                Cerrar detalle
              </b-button>
            </b-card>
          </b-card-body>
        </b-card>

        <!-- El botón de "ver como", únicamente lo puede usar el usuario con rol "admin" -->
      </template>
    </b-table>
    <loading v-else></loading>
    <!--div v-if="isThereData">
      <paginator
        aria-controls="usersTable"
        :disabled="!getAvailableList"
        show-path="userModule/getUsers"
        :resource="getResourceList"
      ></paginator>
    </div-->
    <!-- End Table -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FilteredRow from '../../Common/Table/FilteredRow.vue'

const STORE_MODULE = 'departamentosInversionistaModule'

export default {
  name: 'DepartamentoInversionistaList',

  components: {
    FilteredRow
  },

  props: {
    filter: {
      required: true
    }
  },

  created () {
    this.submit()
  },

  data () {
    return {
      fields: [
        { key: 'num_catastral', label: 'Número catastral', sortable: true },
        { key: 'cuenta_predial', label: 'Cuenta Predial', sortable: true },
        { key: 'subcondominio.nombre', label: 'Subcondominio' },
        { key: 'edificio.nombre', label: 'Edificio' },
        { key: 'tipo.nombre', label: 'Tipo' },
        { key: 'inicio_contrato', label: 'Inicio contrato' },
        { key: 'termino_contrato', label: 'Término contrato' },
        { key: 'renta_inicial', label: 'Renta inicial' },
        { key: 'renta_actual', label: 'Renta actual' },
        { key: 'descuento', label: 'Descuento' },
        { key: 'amueblado', label: 'Amueblado' },
        { key: 'forma_pago.nombre', label: 'Forma de pago', sortable: true },
        { key: 'historial', label: 'Historial' },
        { key: 'acciones', label: 'Acciones' }
      ],
      fields_actualizacion: [
        { key: 'fecha_actualizacion', label: 'Fecha' },
        { key: 'factor_actualizacion', label: 'Factor' },
        { key: 'renta_anterior', label: 'Importe' },
        { key: 'renta_actual', label: 'Actualización' }
      ],
      fields_renovacion: [
        { key: 'fecha_actualizacion', label: 'Fecha' },
        { key: 'fecha_termino_anterior', label: 'Fin contrato' },
        { key: 'fecha_termino_actual', label: 'Renovación' }
      ],

      perPage: 10,

      currentPage: 1,

      totalRows: 0,

      filterOn: ['persona.nombre_completo', 'email']
    }
  },

  computed: {
    ...mapGetters(STORE_MODULE, [
      'getResourceList',
      'getAvailableList',
      'isThereData',
      'getResource'
    ])
  },

  methods: {
    async submit () {
      const { error, message } = await this.$store.dispatch(`${STORE_MODULE}/getResource`)
      this.totalRows = this.getTotalItems

      if (error) this.$notify({ error, message }, 'Propiedades')
    },

    filterFunction (row, filter) {
      if (!filter || filter === '') {
        return false
      }
      return row.num_catastral.toLowerCase().includes(filter.toLowerCase()) ||
        row.cuenta_predial.toLowerCase().includes(filter.toLowerCase()) ||
        row.inicio_contrato.toLowerCase().includes(filter.toLowerCase()) ||
        row.termino_contrato.toLowerCase().includes(filter.toLowerCase()) ||
        (row.inversionista && row.inversionista.identificacion.toLowerCase().includes(filter.toLowerCase()))
    },

    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    emitOnGotItem () {
      this.$emit('on-got-item')
    }
  }
}
</script>

<style scoped>

</style>
